import { FC, ReactElement } from 'react'

export const TitleH1: FC<{ children: ReactElement; text: string }> = ({
  children,
  text,
}) => {
  return (
    <h1 className="title_lg mb-4" data-aos="fade-up">
      {text}
      <span className="p-0 elem_1" data-aos="fade-up" data-aos-delay="100">
        {children}
      </span>
    </h1>
  )
}

export const TitleH2: FC<{ text: string; className: string }> = ({
  text,
  className,
}) => {
  return <h2 className={`title_md ${className}`}>{text}</h2>
}

export const TitleH3: FC<{
  text: string
  className: string
}> = ({ text, className }) => {
  return <h4 className={`title_sm ${className}`}>{text}</h4>
}

export const TitleH2Styled: FC<{
  children: ReactElement
  text: string
  className: string
}> = ({ children, text, className }) => {
  return (
    <h2 className={`title_md ${className}`}>
      {text} <span className="ms-0 blue_belt">{children}</span>
    </h2>
  )
}

export const SubText: FC<{ text: string; className: string }> = ({
  text,
  className,
}) => {
  return <p className={`text_para ${className}`}>{text}</p>
}

// changes 11-03-22

export const TitleH2Styledv2: FC<{
  blueTitle: string
  blueTitle2: string
  text: string
  className: string
}> = ({ blueTitle, blueTitle2, text, className }) => {
  return (
    <h1 className={`title_xl ${className}`}>
      <span>{blueTitle}</span> {text} <span>{blueTitle2}</span>
    </h1>
  )
}

export const TitlePageH1: FC<{
  text: string
  className: string
}> = ({ text, className }) => {
  return <h1 className={`title_md ${className}`}>{text}</h1>
}

export function TitleH3Sm({ text, className }) {
  return <h3 className={`block_title_sm ${className}`}>{text}</h3>
}

export function SubTextSm({ text, className }) {
  return <p className={`text_para_sm ${className}`}>{text}</p>
}

export function SubTextLight({ text, className }) {
  return <p className={`text_para_large ${className}`}>{text}</p>
}
