import ContactModal from 'components/ContactModal'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
// import Cursor from 'scripts/cursor'
const Cursor = dynamic(() => import('scripts/cursor'), {
  ssr: false,
})

const HelperBtn = dynamic(() => import('components/helperBtn'), {
  ssr: false,
})

const Header = dynamic(() => import('layout/LandingPageLayout/Header'), {
  ssr: false,
})
const Footer = dynamic(() => import('layout/LandingPageLayout/Footer'), {
  ssr: false,
})
interface Props {
  children?: React.ReactNode | React.ReactNode[]
  isErrorCls?: any
}
const LandingPageLayout: FC<Props> = ({ children, isErrorCls }) => {
  // const [sticky, setSticky] = useState(true)

  // const dateChecker = () => {
  //   // setSticky(false)
  //   // Add one Hour in current time
  //   // const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000)
  //   // const expireTimeInSeconds = currentTimeInSeconds + 3600
  //   // localStorage.setItem('expireTime', expireTimeInSeconds.toString())
  //   // console.log(expireTimeInSeconds, 'futureTimeInSeconds----------')

  //   // Test
  //   // const currentTimeStamp1 = Math.floor(Date.now() / 1000)
  //   // const twoMinutesLater = currentTimeStamp1 + 2 * 60

  //   // console.log(twoMinutesLater)
  //   const time = parseInt(localStorage.getItem('expireTime') || '0', 10)
  //   ///

  //   // System current time
  //   const currentTimeStamp = Math.floor(Date.now() / 1000)

  //   if (currentTimeStamp > time) {
  //     setSticky(true)
  //   } else {
  //     setSticky(false)
  //   }
  // }
  // useEffect(() => {
  //   dateChecker()
  // })
  const [show, setShow] = useState(false)
  const formModalhandleShow = () => setShow(true)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Check if data is already in localStorage
    // const storedData = localStorage.getItem('webcluesfridayKey')
    // const storedTimestamp = localStorage.getItem('webcluesfridayTimestamp')

    // const lastPopupTime: any = localStorage.getItem('webcluesfridayTimestamp')
    // const currentTime: any = Date.now()
    // const thirtyMinutes: any = 5 * 60 * 1000 // 30 minutes in milliseconds

    // if (!lastPopupTime || currentTime - lastPopupTime > thirtyMinutes) {
    //   setShow(true)
    //   localStorage.setItem('webcluesfridayTimestamp', currentTime)
    // }

    // new code
    // const showPopupLogic = () => {
    //   const lastPopupTime: any = localStorage.getItem('webcluesfridayTimestamp')
    //   const currentTime: any = Date.now()
    //   const thirtyMinutes: any = 3 * 60 * 1000 // 30 minutes in milliseconds

    //   if (!lastPopupTime || currentTime - lastPopupTime > thirtyMinutes) {
    //     setShow(true)
    //     localStorage.setItem('webcluesfridayTimestamp', currentTime)
    //   }
    // }

    // // Check on component mount
    // showPopupLogic()

    // // Set interval to check every 30 minutes
    // const interval = setInterval(() => {
    //   showPopupLogic()
    // }, 1 * 60 * 1000) // Check every 30 minutes

    // return () => clearInterval(interval)

    // End New code

    // Start New code Two
    // Show popup after 10 seconds on initial load
    const showPopupLogic = () => {
      const lastPopupTime: any = localStorage.getItem('webcluesfridayTimestamp')
      const currentTime: any = Date.now()
      const thirtyMinutes: any = 30 * 60 * 1000 // 30 minutes in milliseconds

      if (!lastPopupTime || currentTime - lastPopupTime > thirtyMinutes) {
        setShow(true)
        localStorage.setItem('webcluesfridayTimestamp', currentTime)
      }
    }

    // Show popup after 10 seconds on initial load
    const initialPopupTimeout = setTimeout(() => {
      showPopupLogic()

      // Set interval to check every 1 minutes after the initial display
      const interval = setInterval(() => {
        showPopupLogic()
      }, 1 * 60 * 1000) // Check every 1 minutes

      // Clear the initial timeout after the first display
      clearTimeout(initialPopupTimeout)

      // Clear the interval on component unmount
      return () => clearInterval(interval)
    }, 10000) // 10 seconds in milliseconds

    // Cleanup if component unmounts before the initial timeout
    return () => clearTimeout(initialPopupTimeout)
    // End New code Two

    // Check if the stored data exists and has not expired (1 day in milliseconds)
    // const isDataValid =
    //   storedData &&
    //   storedTimestamp &&
    //   Date.now() - parseInt(storedTimestamp, 10) < 24 * 60 * 60 * 1000

    // if (!isDataValid) {
    //   // Set or update data in localStorage
    //   const newData = 'blackfriday'
    //   localStorage.setItem('webcluesfridayKey', newData)

    //   // Store the current timestamp
    //   localStorage.setItem('webcluesfridayTimestamp', Date.now().toString())
    //   const timer = setTimeout(formModalhandleShow, 10000)
    //   return () => clearTimeout(timer)
    // }
  }, [])
  const router = useRouter()
  const PageUrlCheck =
    router.pathname !== '/contact-us' &&
    router.pathname !== '/get-quote' &&
    router.pathname !== '/thank-you'

  return (
    <div>
      <Header isErrorCls={isErrorCls} />
      {children}
      <Footer />
      <Cursor />
      {PageUrlCheck && (
        <ContactModal isOpen={show} onClose={() => setShow(false)} />
      )}
      <HelperBtn />
    </div>
  )
}

export default LandingPageLayout
