import AOS from 'aos'
import dynamic from 'next/dynamic'
import { FC, useEffect } from 'react'

const Header = dynamic(() => import('layout/GenAiLandingPage/Header'), {
  ssr: false,
})
// const Header = dynamic(() => import('layout/LandingPageLayout/Header'), {
//   ssr: false,
// })
const Footer = dynamic(() => import('layout/LandingPageLayout/Footer'), {
  ssr: false,
})
interface Props {
  children?: React.ReactNode | React.ReactNode[]
  isErrorCls?: any
}

const GenAiLandingPageLayout: FC<Props> = ({ children, isErrorCls }) => {
  useEffect(() => {
    AOS.init()
  })

  return (
    <div className="gen_ai_main">
      <div className="gen_ai_landing_page">
        <Header isErrorCls={isErrorCls} />
        {children}
      </div>
      <Footer />
      {/* <Cursor /> */}
    </div>
  )
}

export default GenAiLandingPageLayout
