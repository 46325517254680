// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  GenAiLandingPageLayout,
  LandingPageLayout,
  NewLandingPageLayout,
} from 'layout/index'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import React, { FC, useEffect } from 'react'
import '../styles/generative-ai-landing-style.scss'
import '../styles/globals.scss'
import '../styles/landing-page-style.scss'
import '../styles/scss/hover.scss'

import { useRouter } from 'next/router'

// import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'

const NextNProgress = dynamic(() => import('nextjs-progressbar'), {
  ssr: false,
})

const ExternalScripts = dynamic(() => import('../scripts/external-scripts'), {
  ssr: false,
})
// export function reportWebVitals(metric) {
//   console.log('metric===', metric)
// }

const MainApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const getCategory = router.query?.path
    ? router.query.path
    : router.pathname.split('/')[1]

  useEffect(() => {
    document.body.className = pageProps.isErrorCls ? 'error-page' : ''

    if (getCategory === 'category' || getCategory === 'tag') {
      document.body.classList.add('error-page')
    }
  })

  interface Props {
    children?: React.ReactNode | React.ReactNode[]
    isErrorCls?: any
  }

  // eslint-disable-next-line react/no-unstable-nested-components

  // const PageLayout: FC = ({ children }) =>
  //   getCategory !== 'ecommerce-development-company' ? (
  //     <LandingPageLayout isErrorCls={pageProps.isErrorCls}>
  //       {children}
  //     </LandingPageLayout>
  //   ) : (
  //     <NewLandingPageLayout>{children}</NewLandingPageLayout>
  //   )

  const PageLayout: FC = ({ children }) => {
    let LandingPage
    if (getCategory === 'ecommerce-development-company') {
      LandingPage = <NewLandingPageLayout>{children}</NewLandingPageLayout>
    } else if (getCategory === 'generative-ai') {
      LandingPage = <GenAiLandingPageLayout>{children}</GenAiLandingPageLayout>
    } else {
      LandingPage = (
        <LandingPageLayout isErrorCls={pageProps.isErrorCls}>
          {children}
        </LandingPageLayout>
      )
    }
    return LandingPage
  }

  return (
    <>
      <ExternalScripts />
      <PageLayout>
        <NextNProgress
          height={5}
          color="#0010f1"
          startPosition={0.3}
          options={{
            showSpinner: false,
          }}
        />
        <Component {...pageProps} />
      </PageLayout>
    </>
  )
}

export default MainApp
