import ContactModal from 'components/ContactModal'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
// import Cursor from 'scripts/cursor'
const Cursor = dynamic(() => import('scripts/cursor'), {
  ssr: false,
})

const HelperBtn = dynamic(() => import('components/helperBtn'), {
  ssr: false,
})

const Header = dynamic(() => import('layout/NewLandingPage/Header'), {
  ssr: false,
})
const Footer = dynamic(() => import('layout/NewLandingPage/Footer'), {
  ssr: false,
})
interface Props {
  children?: React.ReactNode | React.ReactNode[]
  isErrorCls?: any
}
const NewLandingPageLayout: FC<Props> = ({ children, isErrorCls }) => {
  const router = useRouter()

  const [show, setShow] = useState(false)
  const formModalhandleShow = () => setShow(true)
  useEffect(() => {
    const timer = setTimeout(formModalhandleShow, 10000)
    return () => clearTimeout(timer)
  }, [])

  const PageUrlCheck =
    router.pathname !== '/contact-us' &&
    router.pathname !== '/get-quote' &&
    router.pathname !== '/thank-you'

  return (
    <div className="new_landing_page">
      <Header isErrorCls={isErrorCls} />
      {children}
      <Footer />
      <Cursor />
      {PageUrlCheck && (
        <ContactModal isOpen={show} onClose={() => setShow(false)} />
      )}
      <HelperBtn />
    </div>
  )
}

export default NewLandingPageLayout
